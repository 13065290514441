import React, { useEffect } from 'react';
import luxeLogo from './images/luxe360_logo_white.png';
import backgroundImg from './images/la-background.png';
import mobileIcon from './images/mobile.svg';
import mailIcon from './images/mail02.svg';
import instagramIcon from './images/instagram.svg';
import tiktokIcon from './images/tiktok.svg';
import photo1 from './images/photo0.jpg';
import photo2 from './images/photo2.jpeg';
import photo3 from './images/photo3.jpeg';
import vip1 from './images/vip.jpeg';
import vip2 from './images/vip3.jpg';
import vip3 from './images/vip2.jpg';
import promoVideo from './videos/20240615_203507984.MP4';
import './App.css';

function App() {
  useEffect(() => {
    const alertShown = localStorage.getItem('inquiryConfirmedAlertShown');
    const params = new URLSearchParams(window.location.search);

    if (params.get('inquiryConfirmed') === 'true' && !alertShown) {
      alert('Inquiry Confirmed! We will be in touch soon!');
      localStorage.setItem('inquiryConfirmedAlertShown', 'true');
    }
  }, []);

  return (
    <div className="App">

      {/* Header Section */}
      <header className="App-header">
        <div className="background-container">
          <img src={luxeLogo} alt="Luxe 360 LA Logo in white" className="luxe-logo" />
        </div>
      </header>

      {/* Footer Section */}
      <footer className="content-below">
        <h2>CONNECT WITH US</h2>
        <div className="social-icons logo-subheader-font">
          <a href="https://www.instagram.com/luxe360la" target="_blank" rel="noopener noreferrer">
            <img src={instagramIcon} alt="Instagram" className="icon" />
          </a>
          <a href="https://www.tiktok.com/@luxe360la" target="_blank" rel="noopener noreferrer">
            <img src={tiktokIcon} alt="TikTok" className="icon" />
          </a>
          <a href="tel:+18185276297" target="_blank" rel="noopener noreferrer">
            <img src={mobileIcon} alt="Phone" className="icon" />
          </a>
          <a href="mailto:hello@luxe360la.com" target="_blank" rel="noopener noreferrer">
            <img src={mailIcon} alt="Email" className="icon" />
          </a>
        </div>
      </footer>
    </div>
  );
}

export default App;
